import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";
import { CarList } from "../components/CarList";
import { useEffect, useState, useContext } from "react";
import { UserNavigation } from "../components/UserNavigation";
import { AuthContext } from "../contexts/AuthContext";
import { useTranslation } from 'react-i18next';

export function AuctionsPage(props) {
    const [auctions, setAuctions] = useState(null);
    const { apiAccessToken } = useContext(AuthContext);
    const token = apiAccessToken;
    const { t } = useTranslation();

    useEffect(() => {
        if (!token) return;
        const URL = "https://usell.be/api/current-auctions/?query={id, name, end_date, car{id, build_year, kilometers, fuel_type}}";

        const options = {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                Accept: "application/json",
            },
        };

        fetchData();

        async function fetchData() {
            try {
                const response = await fetch(URL, options);
                if (!response.ok) throw new Error("HTTP ERROR! STATUS: " + response.status);
                const data = await response.json();
                setAuctions(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    }, [token]);

    return (
        <>
            <UserNavigation />
            <div className={"auctions-hero"}>
                <div className={"hero-background auctions-background"}></div>
                <Container className={"content content-padding-top-lg content-padding-bottom-lg"}>
                    <h1>{t('lopende_title')}</h1>
                    <h4>{t('lopende_text1')}<br />
                        {t('lopende_text2')}</h4>
                </Container>
            </div>

            <Container className={"content veilingenpagina"}>
                <Row>
                    <CarList auctions={auctions} />
                </Row>
            </Container>
        </>
    );
}