import React from "react";
import {Row, Col, Form} from "react-bootstrap";

export function PasswordForm({ formData, handleChange }) {
    return (
        <>
            <h2>Wijzig uw wachtwoord</h2>
            <Row>
                <Col md={12}>
                    <Form.Group>
                        <Form.Label>Huidig wachtwoord</Form.Label>
                        <Form.Control 
                            type="password" 
                            name="currentPassword" 
                            value={formData.currentPassword} 
                            onChange={handleChange} 
                            placeholder="Huidig wachtwoord" 
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Form.Group>
                        <Form.Label>Nieuw wachtwoord</Form.Label>
                        <Form.Control 
                            type="password" 
                            name="newPassword" 
                            value={formData.newPassword} 
                            onChange={handleChange} 
                            placeholder="Nieuw wachtwoord" 
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Form.Group>
                        <Form.Label>Bevestig uw wachtwoord</Form.Label>
                        <Form.Control 
                            type="password" 
                            name="confirmNewPassword" 
                            value={formData.confirmNewPassword} 
                            onChange={handleChange} 
                            placeholder="Bevestig uw wachtwoord" 
                        />
                    </Form.Group>
                </Col>
            </Row>
        </>
    );
}