import React, { useState, useEffect, useContext } from 'react';
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import { Badge, Card, Col, Row } from "react-bootstrap";
import { AdminNavbar } from "../../components/AdminNav";
import { Spinner } from "react-bootstrap";
import { AuthContext } from "../../contexts/AuthContext";

export function AdminPanelPage(props) {
    const { userMetadata } = useContext(AuthContext);
    const user = userMetadata;
    return (
        <>
            <AdminNavbar />
            <AdminContent user={user} key={user.id} />
        </>
    );
}




const fetchData = async (url, setState, setError) => {
    try {
        const token = process.env.REACT_APP_API_TOKEN;
        const response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json'
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setState(data.count);
    } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
        setError(error.message);
    }
};

function AdminContent(props) {
    const { user } = props;
    const [userCount, setUserCount] = useState(null);
    const [auctionCount, setAuctionCount] = useState(null);
    const [staffCount, setStaffCount] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchData('http://localhost:8000/api/user-count/', setUserCount, setError);
        fetchData('http://localhost:8000/api/auction-count/', setAuctionCount, setError);
        fetchData('http://localhost:8000/api/staff-count/', setStaffCount, setError);


        const timeout = setTimeout(() => {
            setLoading(false);
        }, 2000);

        return () => clearTimeout(timeout);
    }, []);

    if (error) return <p>Error: {error}</p>;
    if (userCount === null || auctionCount === null || staffCount === null || loading) {
        return (
            <div className="spinner-container">
                <Spinner animation="border" role="status" className="spinner">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    return (
        <Container>
            <Row>
                <Row className={"mt-3 mb-3 border-0 text-center"}>
                    <p>
                        <small>Welkom terug, </small>
                        {user.first_name} {user.last_name}
                    </p>
                </Row>
                <Col className={"col-sm-12 col-md-4"}>
                    <Card className="mb-4">
                        <Badge className={"bg-dark"}>Aantal gebruikers {userCount !== null ? userCount : '...'}</Badge>
                    </Card>
                </Col>
                <Col className={"col-sm-12 col-md-4"}>
                    <Card className="mb-4">
                        <Badge className={"bg-dark"}>Aantal
                            veilingen {auctionCount !== null ? auctionCount : '...'}</Badge>
                    </Card>
                </Col>
                <Col className={"col-sm-12 col-md-4"}>
                    <Card className="mb-4">
                        <Badge className={"bg-dark"}>Personeelsleden {staffCount !== null ? staffCount : '...'}</Badge>
                    </Card>
                </Col>
            </Row>
            <Container fluid>
                <Row className={"admin-hover-card mb-5 pb-5"}>
                    <Row className={"px-0 mx-0"}>
                        <Col className={"col-12 col-md-6 col-lg-6"}>
                            <Link as={Link} to="/admin/gebruikers">
                                <Card className={"fixed-size-card border-0 shadow-sm hover-card"}>
                                    <Card.Body>
                                        <Card.Title>
                                            <Row>
                                                <Col>
                                                    <h5>Beheer gebruikers</h5>
                                                </Col>
                                                <Col>
                                                    <i className="bi bi-people-fill float-end p-3 bg-primary-subtle rounded"></i>
                                                </Col>
                                            </Row>
                                        </Card.Title>
                                        <Card.Text className={"d-none d-lg-block d-md-block"}>
                                            Beheer/bekijk gebruikers
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col className={"col-12 col-md-6 col-lg-6"}>
                            <Link as={Link} to="/admin/veilingen">
                                <Card className={"fixed-size-card border-0 shadow-sm hover-card"}>
                                    <Card.Body>
                                        <Card.Title>
                                            <Row>
                                                <Col>
                                                    <h5>Bekijk veilingen</h5>
                                                </Col>
                                                <Col>
                                                    <i className="bi bi-patch-check float-end p-3 bg-success-subtle rounded"></i>
                                                </Col>
                                            </Row>
                                        </Card.Title>
                                        <Card.Text className={"d-none d-md-block d-lg-block"}>
                                            Bekijk alle veilingen die zijn
                                            toegevoegd
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                    </Row>
                    <Row className={"px-0 mx-0 gy-1"}>
                        <Col className={"gap-0 col-12 col-md-6 col-lg-6"}>
                            <Link as={Link} to="/admin/afgekeurde-wagens">
                                <Card className={"fixed-size-card border-0 shadow-sm hover-card"}>
                                    <Card.Body>
                                        <Card.Title>
                                            <Row>
                                                <Col>
                                                    <h5>Afgekeurde wagens</h5>
                                                </Col>
                                                <Col>
                                                    <i className="bi bi-ban float-end p-3 bg-danger-subtle rounded"></i>
                                                </Col>
                                            </Row>
                                        </Card.Title>
                                        <Card.Text className={"d-none d-lg-block d-md-block"}>
                                            Bekijk alle afgekeurde wagens
                                            en reviews
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col className={"gap-0 col-12 col-md-6 col-lg-6"}>
                            <a href={"mailto:info@developementspot.be"}>
                                <Card className={"fixed-size-card border-0 shadow-sm hover-card"}>
                                    <Card.Body>
                                        <Card.Title>
                                            <Row>
                                                <Col className={"col-10"}>
                                                    <h5 className={"text-wrap"}>Contact Developement Spot</h5>
                                                </Col>
                                                <Col>
                                                    <i className="bi bi-telephone float-end p-3 bg-success-subtle rounded"></i>
                                                </Col>
                                            </Row>
                                        </Card.Title>
                                        <Card.Text className={"d-none d-lg-block d-md-block"}>
                                            <p className={"mb-1"}>info@developementspot.be</p>
                                            <a href="tel:+3233767137"><p>03 376 71 37</p></a>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </a>
                        </Col>
                    </Row>
                </Row>
            </Container>
        </Container>
    );
}
