import React from 'react';
import Container from "react-bootstrap/Container";
import { Card, Col, Image, Row } from "react-bootstrap";
import Lottie from "lottie-react";
import trustAnimation from "../lotties/trust.json"
import uxAnimation from "../lotties/ux.json"
import { useTranslation } from 'react-i18next';
export function AboutUsPage(props) {


    return (
        <>
            <AboutUsPageBanner />
            <AboutUsPageContent />
        </>
    );

    function AboutUsPageBanner() {
        const { t } = useTranslation();
        return (
            <div className={"about-us-banner-picture align-items-center text-center h100 d-flex"}>
                <div className="container">

                    <h1 className="text-white display-1 font-weight-bold">
                        {t('us_title')} <span className="text-primary border-bottom border-primary">{t('us_title2')}</span> {t('us_title3')}
                    </h1>
                </div>
            </div>

        );
    }


    function AboutUsPageContent() {
        const { t } = useTranslation();
        return (
            <>
                <Container className={'text-center p-4'}>
                    <h2 className="over-ons-titel shadow-text">{t('pillars_title')}</h2>
                    <p>{t('pillars_text')}</p>
                </Container>
                <Container className={"mb-5"}>
                    <Row>
                        <Col xs={12} lg={6} className={'custom-cards-col'}>
                            <Card className={"bg-gradient card border-0"}>
                                <Card.Body className={"text-center"}>
                                    <Lottie animationData={trustAnimation} loop={true}
                                        style={{ transform: "scale(0.8)" }} />
                                </Card.Body>
                                <Card.Title className={'text-center float-end over-ons-titel shadow-text'}>
                                    {t('first_title')}
                                </Card.Title>
                                <Card.Body>
                                    {t('first_text')}
                                </Card.Body>
                                <div className="d-flex justify-content-center mt-5 pt-5 logoover">
                                    <Image src={"images/new-logo.png"}
                                        style={{
                                            maxHeight: '200px',

                                        }}
                                        className={"text-center"} />
                                </div>
                            </Card>
                        </Col>
                        <Col xs={12} lg={6} className={"custom-cards-col"}>
                            <Card className={"border-0"}>
                                <Card.Body>
                                    <Lottie animationData={uxAnimation} loop={true} style={{ transform: "scale(0.6)" }} />
                                </Card.Body>
                                <Card.Title className={'text-center over-ons-titel shadow-text'}>
                                    {t('last_title')}
                                </Card.Title>
                                <Card.Body>
                                    {t('last_text')}
                                </Card.Body>
                                <Card.Body className={"mt-1 pt-1"}>
                                    {t('last_text2')}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}