import React from 'react';
import {Row, Col} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';

export function Footer() {

    const { t } = useTranslation();

    return (
        <div className="footer" style={{position: "relative", color: 'white'}}>
            <div className="footer-background-image"></div>
            <Container>
                <Row className={"align-items-start d-flex justify-content-center"} style={{margin: "0rem"}}>
                    <Col sm={12} md={6} lg={3}
                         className={"float-sm-left align-items-start d-flex justify-content-center"}>
                        <img className={"footer-logo-white"} src="/images/logo_white.png" alt={"usell logo"}/>
                    </Col>
                    <Col sm={12} md={6} lg={3}
                         className={"float-sm-start align-items-start d-flex justify-content-lg-center"}>
                        <ul>
                            <li className={"text-secondary"}><h5>{t('footer1')}</h5></li>
                            <li><Link to={"/"}>{t('footer2')}</Link></li>
                            <li><Link to={"/about-us"}>{t('footer3')}</Link></li>
                            <li><Link to={"/contact"}>{t('footer4')}</Link></li>
                        </ul>
                    </Col>
                    <Col sm={12} md={6} lg={3}
                         className={"float-sm-start align-items-start d-flex justify-content-lg-center"}>
                        <ul>
                            <li className={"text-secondary"}><h5>{t('footer5')}</h5></li>
                            <li>Dorpsstraat 111 bus 2,</li>
                            <li>2221 Heist-op-den-Berg</li>
                            <li><a href="tel:+32493330819">+32 493/33.08.19</a></li>
                            <li><a href="mailto:info@usell.be">info@usell.be</a></li>
                            <li>BE 0764.545.783</li>
                        </ul>
                    </Col>
                    <Col sm={12} md={6} lg={3}
                         className={"float-sm-start align-items-start d-flex justify-content-lg-center"}>
                        <ul>
                            <li className={"text-secondary"}><h5>{t('footer6')}</h5></li>
                            <li><Link to={"/legal"}>{t('footer7')}</Link></li>
                            <li><Link to={"/contact"}>{t('footer8')}</Link></li>
                            <li><Link to={"https://cdn.cookiecode.nl/privacy/usell.be/nl/pdf"}>{t('footer9')}</Link></li>
                            <li><Link to={"https://cdn.cookiecode.nl/cookie/usell.be/nl/pdf"}>{t('footer10')}</Link></li>
                    </ul>
                </Col>
            </Row>
            <Row>
                <Container>
                    <hr className={"text-secondary opacity-75"}/>
                </Container>
            </Row>
            <Row>
                <Col xs={12} sm={12} md={12} lg={10}>
                    <p className={"text-secondary"}>{t('footer_text')}</p>
                </Col>
                <Col xs={12} sm={12} md={12} lg={2} className="d-flex justify-content-lg-end">
                    <p>
                        <a className={"mx-2"} href={"https://facebook.com"}><i className="bi bi-facebook"></i></a>
                        <a className={"mx-2"} href={"https://instagram.com"}><i className="bi bi-instagram"></i></a>
                        <a className={"mx-2"} href={"https://x.com"}><i className="bi bi-twitter-x"></i></a>
                    </p>
                </Col>
            </Row>
        </Container>
</div>
)
    ;
}