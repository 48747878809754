import React, { createContext, useState, useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Spinner } from "react-bootstrap";

export const AuthContext = createContext();

const checkUserExists = async (token, user) => {
    const cleanedSub = user.sub.replace('|', '');
    try {
        const response = await fetch(`https://usell.be/api/user/${cleanedSub}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (response.status === 404) {
            return false;
        }
        return response.ok;
    } catch (error) {
        console.error('Error checking if user exists', error);
        return false;
    }
};

const createUser = async (token, user) => {
    const cleanedSub = user.sub.replace('|', '');

    try {
        const response = await fetch('https://usell.be/api/create-user/', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Authsub: cleanedSub,
                email: user.email,
                first_name: user.given_name,
                last_name: user.family_name,
                password: 'password',
                status: true,
                type: 'seller'
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to create user');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error creating user', error);
        return null;
    }
};

const checkProfileCompleteness = (userMetadata) => {
    return userMetadata.first_name && userMetadata.last_name && userMetadata.birthdate && userMetadata.type && userMetadata.address;
};

export const AuthProvider = ({ children }) => {
    const { user, isAuthenticated, logout, loginWithRedirect } = useAuth0();
    const [userMetadata, setUserMetadata] = useState(() => {
        const storedMetadata = sessionStorage.getItem('userMetadata');
        return storedMetadata ? JSON.parse(storedMetadata) : null;
    });
    const [loading, setLoading] = useState(true);
    const apiAccessTokenRef = useRef(null);

    useEffect(() => {
        const fetchUserMetadata = async () => {
            if (isAuthenticated && user) {
                try {
                    const tokenExpiry = sessionStorage.getItem('tokenExpiry');
                    const currentTime = Math.floor(Date.now() / 1000);

                    if (!apiAccessTokenRef.current || currentTime >= tokenExpiry) {
                        apiAccessTokenRef.current = await fetchApiAccessToken();

                        if (!apiAccessTokenRef.current) {
                            console.error('Failed to fetch API access token');
                            logout();
                            return;
                        }

                        const expiresIn = 3600;
                        sessionStorage.setItem('tokenExpiry', currentTime + expiresIn);
                    } else {
                    }

                    const userExists = await checkUserExists(apiAccessTokenRef.current, user);

                    if (!userExists) {
                        const newUserMetadata = await createUser(apiAccessTokenRef.current, user);
                        if (newUserMetadata) {
                            setUserMetadata(newUserMetadata);
                            sessionStorage.setItem('userMetadata', JSON.stringify(newUserMetadata));
                            sessionStorage.setItem('needsRefresh', 'true');
                            window.location.reload();
                        }
                    } else {
                        const cleanedSub = user.sub.replace('|', '');
                        const response = await fetch(`https://usell.be/api/user/${cleanedSub}`, {
                            headers: {
                                Authorization: `Bearer ${apiAccessTokenRef.current}`,
                            },
                        });
                        const data = await response.json();
                        setUserMetadata(data);
                        sessionStorage.setItem('userMetadata', JSON.stringify(data));

                        if (!checkProfileCompleteness(data) && window.location.pathname !== '/vervolledig-profiel') {
                            window.location.href = '/vervolledig-profiel';
                        } else if (checkProfileCompleteness(data) && window.location.pathname === '/vervolledig-profiel') {
                            window.location.href = '/dashboard/profiel';
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user metadata', error);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        const needsRefresh = sessionStorage.getItem('needsRefresh');
        if (needsRefresh === 'true') {
            sessionStorage.removeItem('needsRefresh');
            fetchUserMetadata();
        } else {
            fetchUserMetadata();
        }
    }, [isAuthenticated, user, logout]);

    const fetchApiAccessToken = async () => {
        try {
            const response = await fetch(`https://${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
                    client_secret: process.env.REACT_APP_AUTH0_CLIENT_SECRET,
                    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                    grant_type: 'client_credentials',
                }),
            });
            const data = await response.json();
            return data.access_token;
        } catch (error) {
            console.error('Error fetching API access token', error);
            return null;
        }
    };

    return (
        <AuthContext.Provider value={{ user, userMetadata, isAuthenticated, logout, loginWithRedirect, apiAccessToken: apiAccessTokenRef.current }}>
            {loading ?
                <div className="spinner-container">
                    <Spinner animation="border" role="status" className="spinner">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
                :
                children
            }
        </AuthContext.Provider>
    );
};