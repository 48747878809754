import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";
import { CarList } from "../../components/CarList";
import { UserNavigation } from "../../components/UserNavigation";
import { AuthContext } from "../../contexts/AuthContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

export function FollowingAuctionsPage(props) {
    const { userMetadata, apiAccessToken } = useContext(AuthContext);
    const token = apiAccessToken;
    const user = userMetadata;
    const [auctions, setAuctions] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        const fetchData = async () => {
            if (!user?.id || !token) return;
            const URL = `https://usell.be/api/following-auctions/${user.id}/`;
            const options = {
                method: "GET",
                headers: {
                    authorization: `Bearer ${token}`,
                    Accept: "application/json",
                },
            };

            try {
                const response = await fetch(URL, options);
                if (!response.ok) throw new Error(`HTTP ERROR! STATUS: ${response.status}`);
                const data = await response.json();
                setAuctions(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [user.id, token]);
    
    if (!auctions) return "Loading";

    return (
        <>
            <UserNavigation />
            <div className={"following-hero"}>
                <div className={"hero-background following-background"}></div>
                <Container className={"content content-padding-top-lg content-padding-bottom-lg"}>
                    <h1>{t('following_title')}</h1>
                    <h4>{t('following_text')}</h4>
                </Container>
            </div>

            <Container className={"content volgende"}>
                <Row>
                    <CarList auctions={auctions} following={true} />
                </Row>
            </Container>
        </>
    );
}