import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { Button, Carousel, Col, Form, Modal, Row, Card, Collapse } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown, faCar, faTachometerAlt, faDoorOpen, faCog, faGasPump, faTools, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../contexts/AuthContext";
import { UserNavigation } from "../components/UserNavigation";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";

export function AuctionDetailsPage(props) {
    const { id } = useParams();
    const { userMetadata, apiAccessToken } = useContext(AuthContext);
    const user = userMetadata;
    const [auction, setAuction] = useState(null);
    const [show, setShow] = useState(false);
    const [formAmount, setFormAmount] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showBids, setShowBids] = useState(false);
    const [openOptions, setOpenOptions] = useState(false);
    const [openDamages, setOpenDamages] = useState(false);
    const handleClose = () => {
        setShow(false);
        setErrorMessage("");
    };
    const handleShow = () => setShow(true);
    const handleShowBids = () => setShowBids(true);
    const handleCloseBids = () => setShowBids(false);

    const addBid = async (e) => {
        e.preventDefault();

        const URL = `https://usell.be/api/add-bid/${id}/`;
        const token = apiAccessToken;
        const options = {
            method: "PATCH",
            headers: {
                authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                bids: [
                    {
                        user_id: user.id,
                        amount: formAmount,
                        date: new Date().toISOString(),
                    },
                ],
            }),
        };

        try {
            const response = await fetch(URL, options);
            const data = await response.json();

            if (!response.ok) {
                if (data.error && data.min_increment) {
                    setErrorMessage(`Uw bod moet minimaal €${data.min_increment} hoger zijn dan het huidige hoogste bod.`);
                } else {
                    setErrorMessage(data.error || "Er is een fout opgetreden.");
                }
                return;
            }

            setAuction(data.auction_data);
            handleClose();
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const URL = `https://usell.be/api/auction/${id}/`;
        const token = apiAccessToken;
        const options = {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                Accept: "application/json",
            },
        };

        const fetchData = async () => {
            try {
                const response = await fetch(URL, options);
                if (!response.ok) throw new Error(`HTTP ERROR! STATUS: ${response.status}`);
                const data = await response.json();
                setAuction(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [id, apiAccessToken]);

    if (!auction) return <h1>LOADING</h1>;

    const highestBid = auction.bids.length > 0 ? Math.max(...auction.bids.map(bid => bid.amount)) : auction.bottom_price;
    const winningBid = auction.bids.length > 0 ? auction.bids[auction.bids.length - 1] : null;

    return (
        <>
            <UserNavigation />
            <div className={"auctions-hero"}>
                <div className={"hero-background detail-auctions-background"} style={{
                    backgroundImage: "url('/images/cars/audi.jpeg')",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat"
                }}></div>
                <Container className={"content content-padding-top-lg content-padding-bottom-lg mobiel-auction"}>
                    <h2 className="shadow-text auction-detail-page-p">{auction.name || "OPEL CORSA TUNING"}</h2>
                    <h4 className={"fw-bold shadow-text auction-detail-page-p"}>Huidig Hoogste Bod: €{highestBid}</h4>
                    <h5 className="shadow-text auction-detail-page-p">Vanaf €{auction.bottom_price}</h5>
                </Container>
            </div>
            <Container className={"content detail"}>
                <Row>
                    <Col lg={5}>
                        <Carousel className={"car-carousel"}>
                            {auction.images.map((image, index) => (
                                <Carousel.Item interval={1000} key={index}>
                                    <img src={image.url} alt="car" className="rounded-carousel-img" />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </Col>
                    <Col lg={7}>
                        <h2 className={"mb-0 shadow-text auction-detail-page"} style={{ color: '#2196F3' }}>{auction.name}</h2>
                        <Row>
                            <Col lg={6}>
                                <Card className="car-specs-card">
                                    <Card.Body>
                                        <h4 className="shadow-text" style={{ color: '#2196F3' }}>ALGEMEEN</h4>
                                        <ul className="car-specs-list">
                                            <li><FontAwesomeIcon icon={faCar} /> {auction.car.brand_name} {auction.car.model} {auction.car.build_year}</li>
                                            <li><FontAwesomeIcon icon={faCar} /> {auction.car.generation} {auction.car.series}</li>
                                            <li><FontAwesomeIcon icon={faTachometerAlt} /> {auction.car.kilometers} KM</li>
                                            <li><FontAwesomeIcon icon={faDoorOpen} /> {auction.car.number_of_doors} deur(en)</li>
                                        </ul>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={6}>
                                <Card className="car-specs-card">
                                    <Card.Body>
                                        <h4 className="shadow-text" style={{ color: '#2196F3' }}>AANDRIJVING</h4>
                                        <ul className="car-specs-list">
                                            <li><FontAwesomeIcon icon={faCog} /> {auction.car.engine}</li>
                                            <li><FontAwesomeIcon icon={faCog} /> {auction.car.drive}</li>
                                            <li><FontAwesomeIcon icon={faTools} /> {auction.car.transmission}</li>
                                            <li><FontAwesomeIcon icon={faGasPump} /> {auction.car.fuel_type}</li>
                                        </ul>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={6}>
                                <Card className="car-specs-card">
                                    <Card.Body>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h4 className="shadow-text" style={{ color: '#2196F3' }}>OPTIES</h4>
                                            <FontAwesomeIcon
                                                icon={openOptions ? faChevronUp : faChevronDown}
                                                onClick={() => setOpenOptions(!openOptions)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </div>
                                        <Collapse in={openOptions}>
                                            <ul className="car-specs-list">
                                                {auction.car.options.length > 0 ? (
                                                    auction.car.options.map(option => (
                                                        <li key={option.id}>{option.description}</li>
                                                    ))
                                                ) : (
                                                    <li>Geen opties beschikbaar</li>
                                                )}
                                            </ul>
                                        </Collapse>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={6}>
                                <Card className="car-specs-card">
                                    <Card.Body>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h4 className="shadow-text" style={{ color: '#2196F3' }}>SCHADEPUNTEN</h4>
                                            <FontAwesomeIcon
                                                icon={openDamages ? faChevronUp : faChevronDown}
                                                onClick={() => setOpenDamages(!openDamages)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </div>
                                        <Collapse in={openDamages}>
                                            <ul className="car-specs-list">
                                                {auction.car.damages.length > 0 ? (
                                                    auction.car.damages.map(damage => (
                                                        <li key={damage.id}>{damage.description}</li>
                                                    ))
                                                ) : (
                                                    <li>Geen schadepunten beschikbaar</li>
                                                )}
                                            </ul>
                                        </Collapse>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card className="car-specs-card">
                                    <Card.Body>
                                        <h4 className="shadow-text" style={{ color: '#2196F3' }}>DOCUMENTEN</h4>
                                        <ul className="car-specs-list eenlijn">
                                            {auction.car.boorddocument_url ? (
                                                <li>
                                                    <FontAwesomeIcon icon={faFileAlt} />{" "}
                                                    <a href={auction.car.boorddocument_url} target="_blank" rel="noopener noreferrer">
                                                        Keuringsbewijs
                                                    </a>
                                                </li>
                                            ) : (
                                                <li>Keuringsbewijs niet beschikbaar</li>
                                            )}
                                            {auction.car.kenteken_url ? (
                                                <li>
                                                    <FontAwesomeIcon icon={faFileAlt} />{" "}
                                                    <a href={auction.car.kenteken_url} target="_blank" rel="noopener noreferrer">
                                                        Kenteken Voorkant
                                                    </a>
                                                </li>
                                            ) : (
                                                <li>Kenteken Voorkant niet beschikbaar</li>
                                            )}
                                            {auction.car.kenteken2_url ? (
                                                <li>
                                                    <FontAwesomeIcon icon={faFileAlt} />{" "}
                                                    <a href={auction.car.kenteken2_url} target="_blank" rel="noopener noreferrer">
                                                        Kenteken Achterkant
                                                    </a>
                                                </li>
                                            ) : (
                                                <li>Kenteken Achterkant niet beschikbaar</li>
                                            )}
                                        </ul>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="my-4">
                    <Col sm={9}>
                        <h4 className="shadow-text auction-detail-page" style={{ color: '#2196F3' }}>BIEDINGEN</h4>
                        {winningBid && (
                            <div className="winning-bid auction-detail-page-p2">
                                <FontAwesomeIcon icon={faCrown} className="text-warning me-2" />
                                Winnende Bod: €{winningBid.amount}
                            </div>
                        )}
                    </Col>
                    <Col sm={3} className="d-flex justify-content-end">
                        {user.type !== "seller" && (
                            <Button
                                variant="primary"
                                onClick={handleShow}
                                className="me-2"
                                disabled={auction.status !== "ONGOING"}
                            >
                                Maak bod
                            </Button>
                        )}
                        <Button variant="secondary" onClick={handleShowBids} className="ms-2">
                            Bekijk alle biedingen
                        </Button>
                    </Col>
                </Row>
            </Container>
            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={addBid}>
                    <Modal.Header closeButton>
                        <Modal.Title>Plaats uw bod</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="formBidAmount">
                            <Form.Label>Bedrag</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder={parseFloat(highestBid).toFixed(2)}
                                value={formAmount}
                                onChange={e => setFormAmount(parseFloat(e.target.value))}
                                autoFocus
                            />
                        </Form.Group>
                        {errorMessage && <p className="text-danger">{errorMessage}</p>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Annuleer
                        </Button>
                        <Button type="submit" variant="primary">
                            Plaats bod
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <Modal show={showBids} onHide={handleCloseBids}>
                <Modal.Header closeButton>
                    <Modal.Title>Alle biedingen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul>
                        {auction.bids.map((bid, index) => (
                            <li key={index}>
                                €{bid.amount} (Geboden op: {new Date(bid.date).toLocaleString()})
                            </li>
                        ))}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseBids}>
                        Sluiten
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}