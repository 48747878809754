import React, { useContext, useEffect, useState, useCallback } from "react";
import Container from "react-bootstrap/Container";
import { Row, Button, Tabs, Tab, Form, InputGroup } from "react-bootstrap";
import { CarList } from "../../components/CarList";
import { UserNavigation } from "../../components/UserNavigation";
import { AuthContext } from "../../contexts/AuthContext";
import { AiOutlineSearch } from "react-icons/ai";
import { useTranslation } from 'react-i18next';

export function UserAuctionsPage(props) {
    const { userMetadata, apiAccessToken } = useContext(AuthContext);
    const user = userMetadata;
    const token = apiAccessToken;
    const [auctions, setAuctions] = useState([]);
    const [filter, setFilter] = useState("active");
    const [searchTerm, setSearchTerm] = useState("");
    const { t } = useTranslation();

    const fetchAuctions = useCallback(async (type) => {
        if (!user?.id || !token) return;
        const URL =
            type === "active"
                ? `https://usell.be/api/current-auctions/user/${user.id}/`
                : `https://usell.be/api/past-auctions/user/${user.id}/`;
        const options = {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                Accept: "application/json",
            },
        };

        try {
            const response = await fetch(URL, options);
            if (!response.ok) throw new Error(`HTTP ERROR! STATUS: ${response.status}`);
            const data = await response.json();
            setAuctions(data);
        } catch (error) {
            console.error(error);
        }
    }, [user, token]);

    useEffect(() => {
        if (!user?.id || !token) return;
        fetchAuctions(filter);
    }, [user, token, filter, fetchAuctions]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredAuctions = auctions.filter(auction =>
        auction.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
            <UserNavigation />
            <div className="user-auctions-hero">
                <div className="hero-background user-auctions-background"></div>
                <Container className="content content-padding-top-lg content-padding-bottom-lg auctionText">
                    <h1>{t('userveiling')}</h1>
                    <h4>{t('userveiling2')}</h4>
                    <Tabs activeKey={filter} onSelect={setFilter}>
                        <Tab eventKey="active" title={t('userveiling3')}>
                            <Button variant="primary" className="mt-3" onClick={() => fetchAuctions("active")}>
                            {t('userveiling4')}
                            </Button>
                        </Tab>
                        <Tab eventKey="history" title={t('userveiling5')}>
                            <Button variant="primary" className="mt-3" onClick={() => fetchAuctions("history")}>
                            {t('userveiling6')}
                            </Button>
                        </Tab>
                    </Tabs>
                </Container>
            </div>

            <Container className="content">
                <InputGroup className="mb-4">
                    <Form.Control
                        placeholder={t('userveiling7')}
                        aria-label="Zoek veilingen"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                    <InputGroup.Text>
                        <AiOutlineSearch />
                    </InputGroup.Text>
                </InputGroup>
                <h3 className="uw-veilingen">{filter === "active" ? t('userveiling8') : t('userveiling9') }</h3>
                <Row>
                    <CarList auctions={filteredAuctions} />
                </Row>
            </Container>
        </>
    );
}