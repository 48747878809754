import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Table, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { AdminNavbar } from "../../components/AdminNav";

function AdminAuctionsPage() {
    return (
        <>
            <AdminNavbar />
            <AdminAuctions />
        </>
    );
}

const AdminAuctions = () => {
    const [auctions, setAuctions] = useState(null);
    const [filterStatus, setFilterStatus] = useState("");

    useEffect(() => {
        fetchData();
    }, [filterStatus]);

    const fetchData = async () => {
        let URL = "http://localhost:8000/api/auctions/";
        if (filterStatus) {
            URL += `?status=${filterStatus}`;
        }
        const token = process.env.REACT_APP_API_TOKEN;
        const options = {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                Accept: "application/json",
            },
        };

        try {
            const response = await fetch(URL, options);
            if (!response.ok) throw new Error("HTTP ERROR! STATUS: " + response.status);
            const data = await response.json();
            setAuctions(data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleDelete = async (auctionId) => {
        const URL = `http://localhost:8000/api/update-auction/${auctionId}/`;
        const token = process.env.REACT_APP_API_TOKEN;
        const options = {
            method: "PATCH",
            headers: {
                authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "status": "CANCELLED"
            })
        };

        try {
            const response = await fetch(URL, options);
            if (!response.ok) throw new Error("HTTP ERROR! STATUS: " + response.status);
            const data = await response.json();
            setAuctions((prevAuctions) =>
                prevAuctions.map((auction) => (auction.id === auctionId ? { ...auction, status: "CANCELLED" } : auction))
            );
        } catch (error) {
            console.error(error);
        }
    };

    if (!auctions) return "Loading";

    return (
        <Container>
            <Row>
                <Col sm={12} md={3}>
                    <aside>
                        <h4>Filters</h4>
                        <Form.Group>
                            <Form.Label>Status:</Form.Label>
                            <Form.Control as="select" value={filterStatus} onChange={(e) => setFilterStatus(e.target.value)}>
                                <option value="">All</option>
                                <option value="UPCOMING">Upcoming</option>
                                <option value="ONGOING">Ongoing</option>
                                <option value="COMPLETED">Completed</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Financing:</Form.Label>
                            <Form.Check type="checkbox" label="Available" />
                        </Form.Group>
                    </aside>
                </Col>
                <Col sm={12} md={9}>
                    <Table responsive className="table table-striped table-bordered mt-3">
                        <thead>
                            <tr>
                                <th>Auction</th>
                                <th>Owner</th>
                                <th>Status</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {auctions.map((auction, index) => (
                                <tr key={index} style={{ cursor: 'pointer' }}>
                                    <td>{auction.id}</td>
                                    <td>{auction.owner.first_name} {auction.owner.last_name}</td>
                                    <td>{auction.status}</td>
                                    <td>{auction.start_date}</td>
                                    <td>{auction.end_date}</td>
                                    <td>
                                        <a href={`/veiling/${auction.id}`}>
                                            <FontAwesomeIcon className={""} icon={faEye} color="gray" />
                                        </a>
                                        <Button variant="danger" size="sm" onClick={() => handleDelete(auction.id)} >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
};

export default AdminAuctionsPage;