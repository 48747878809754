import React, { useState, useContext, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { AuthContext } from "../contexts/AuthContext";

export function CompleteProfilePage() {
    const { userMetadata, setUserMetadata, apiAccessToken } = useContext(AuthContext);
    const [profile, setProfile] = useState(userMetadata || JSON.parse(sessionStorage.getItem('userMetadata')));
    const [error, setError] = useState(null);
    const [ageError, setAgeError] = useState(null);
    const [consentError, setConsentError] = useState(null);

    const checkProfileCompleteness = (userMetadata) => {
        return userMetadata.first_name && userMetadata.last_name && userMetadata.birthdate && userMetadata.type && userMetadata.address_id;
    };

    useEffect(() => {
        if (userMetadata && checkProfileCompleteness(userMetadata)) {
            window.location.href = '/dashboard/profiel';
        }
    }, [userMetadata]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        const snakeCaseName = name.replace(/([A-Z])/g, "_$1").toLowerCase();
        setProfile({ ...profile, [snakeCaseName]: value });

        if (name === "birthdate") {
            const today = new Date();
            const birthDate = new Date(value);

            const yearDiff = today.getFullYear() - birthDate.getFullYear();
            const monthDiff = today.getMonth() - birthDate.getMonth();
            const dayDiff = today.getDate() - birthDate.getDate();

            console.log("Today's date:", today);
            console.log("Birthdate:", birthDate);
            console.log("Year difference:", yearDiff);
            console.log("Month difference:", monthDiff);
            console.log("Day difference:", dayDiff);

            if (yearDiff < 18 || yearDiff < 18 && monthDiff > 0 || yearDiff < 18 && dayDiff > 0 || yearDiff === 18 && monthDiff > 0 || yearDiff === 18 && dayDiff > 0 && monthDiff === 0) {
                setAgeError("You must be at least 18 years old.");
            } else {
                setAgeError(null);
            }
        }

    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setProfile({ ...profile, [name]: checked });
    };

    const fetchUpdatedUser = async () => {
        try {
            const response = await fetch(`https://usell.be/api/user/${profile.Authsub}/`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${apiAccessToken}`,
                }
            });

            if (!response.ok) {
                console.error("Failed to fetch updated user data");
                return;
            }

            const updatedUser = await response.json();

            if (!updatedUser.id) {
                console.error("Updated user data is missing the `id` field");
                return;
            }

            setUserMetadata(updatedUser);
            sessionStorage.setItem('userMetadata', JSON.stringify(updatedUser));
            sessionStorage.setItem('needsRefresh', 'true');
            if (checkProfileCompleteness(updatedUser)) {
                window.location.reload();
            }
        } catch (error) {
            console.error("Error fetching updated user data:", error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setConsentError(null);

        if (!profile.gdpr_consent || !profile.privacy_policy_consent || !profile.terms_of_service_consent) {
            setConsentError("U moet akkoord gaan met alle voorwaarden en beleidsregels om door te gaan.");
            return;
        }

        if (ageError) {
            return;
        }

        const address = {
            street: profile.street,
            house_number: profile.house_number,
            zip_code: profile.zip_code,
            city: profile.city,
            province: profile.province,
            country: profile.country,
            phone_number: profile.phone_number,
        };

        try {
            const addressResponse = await fetch(`https://usell.be/api/address/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${apiAccessToken}`,
                },
                body: JSON.stringify(address),
            });

            if (!addressResponse.ok) {
                const errorText = await addressResponse.text();
                throw new Error(errorText);
            }

            const newAddress = await addressResponse.json();

            const updatedUser = {
                id: profile.id,
                Authsub: profile.Authsub,
                first_name: profile.first_name,
                last_name: profile.last_name,
                email: profile.email,
                birthdate: profile.birthdate,
                address_id: newAddress.id,
                profile_picture: profile.profile_picture,
                status: profile.status,
                website: profile.website,
                gdpr_consent: profile.gdpr_consent,
                privacy_policy_consent: profile.privacy_policy_consent,
                terms_of_service_consent: profile.terms_of_service_consent,
            };

            if (profile.vat_number) {
                updatedUser.vat_number = profile.vat_number;
                updatedUser.type = "buyer";
            } else {
                updatedUser.type = "seller";
            }

            if (!profile.id) {
                console.error("Profile ID is undefined.");
                setError("Profile ID is undefined. Please try again.");
                return;
            }

            const userResponse = await fetch(`https://usell.be/api/update-user/${profile.id}/`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${apiAccessToken}`,
                },
                body: JSON.stringify(updatedUser),
            });

            if (!userResponse.ok) {
                const errorText = await userResponse.text();
                throw new Error(errorText);
            }

            await fetchUpdatedUser();

            window.location.reload();

        } catch (error) {
            console.error("Error in handleSubmit:", error);
            setError(error.message);
        }
    };

    return (
        <Container>
            <Row className="justify-content-center">
                <Col md={8}>
                    <h2>Vervolledig uw profiel</h2>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Voornaam</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="first_name"
                                        value={profile.first_name}
                                        onChange={handleChange}
                                        placeholder="Voornaam"
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Achternaam</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="last_name"
                                        value={profile.last_name}
                                        onChange={handleChange}
                                        placeholder="Achternaam"
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Geboortedatum</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="birthdate"
                                        value={profile.birthdate}
                                        onChange={handleChange}
                                        placeholder="Geboortedatum"
                                        required
                                    />
                                </Form.Group>
                                {ageError && <div className="text-danger">{ageError}</div>}
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>BTW-nummer</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="vat_number"
                                        value={profile.vat_number}
                                        onChange={handleChange}
                                        placeholder="BTW-nummer"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Straat</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="street"
                                        value={profile.street}
                                        onChange={handleChange}
                                        placeholder="Straat"
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Huisnummer</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="house_number"
                                        value={profile.house_number}
                                        onChange={handleChange}
                                        placeholder="Huisnummer"
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Postcode</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="zip_code"
                                        value={profile.zip_code}
                                        onChange={handleChange}
                                        placeholder="Postcode"
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Stad</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="city"
                                        value={profile.city}
                                        onChange={handleChange}
                                        placeholder="Stad"
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Provincie</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="province"
                                        value={profile.province}
                                        onChange={handleChange}
                                        placeholder="Provincie"
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Land</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="country"
                                        value={profile.country}
                                        onChange={handleChange}
                                        placeholder="Land"
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Form.Group>
                                    <Form.Label>Telefoonnummer</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="phone_number"
                                        value={profile.phone_number}
                                        onChange={handleChange}
                                        placeholder="Telefoonnummer"
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group controlId="gdprConsent">
                                    <Form.Check
                                        type="checkbox"
                                        name="gdpr_consent"
                                        label="Ik ga akkoord met de GDPR-voorwaarden"
                                        checked={profile.gdpr_consent}
                                        onChange={handleCheckboxChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="privacyPolicyConsent">
                                    <Form.Check
                                        type="checkbox"
                                        name="privacy_policy_consent"
                                        label="Ik ga akkoord met het Privacybeleid"
                                        checked={profile.privacy_policy_consent}
                                        onChange={handleCheckboxChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="termsOfServiceConsent">
                                    <Form.Check
                                        type="checkbox"
                                        name="terms_of_service_consent"
                                        label="Ik ga akkoord met de Gebruiksvoorwaarden"
                                        checked={profile.terms_of_service_consent}
                                        onChange={handleCheckboxChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        {consentError && <div className="text-danger">{consentError}</div>}
                        <Row>
                            <Col md={12}>
                                <Button variant="primary" type="submit">Vervolledig uw gegevens</Button>
                            </Col>
                        </Row>
                        {error && <div className="text-danger">{error}</div>}
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}