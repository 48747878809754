import React, {useEffect, useState} from 'react';
import {Container, Form, Table, Button, Modal} from 'react-bootstrap';
import { AdminNavbar } from "../../components/AdminNav";

const AdminDisabledAuctionsPage = () => {

    const [auctions, setAuctions] = useState(null);
    const [selectedAuction, setSelectedAuction] = useState(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const URL = "https://usell.be/api/disabled-auctions/";
        const token = process.env.REACT_APP_API_TOKEN;
        const options = {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                Accept: "application/json",
            },
        };

        fetchData();

        async function fetchData() {
            try {
                const response = await fetch(URL, options);
                if (!response.ok) throw new Error("HTTP ERROR! STATUS: " + response.status);
                const data = await response.json();
                setAuctions(data);
            } catch (error) {
                console.error(error);
            }
        }
    }, []);

    if(!auctions) return "Loading";

    return (
        <>
            <AdminNavbar />
            <AdminManageUsers auctions={auctions} setAuctions={setAuctions} setSelectedAuction={setSelectedAuction} selectedAuction={selectedAuction} showModal={showModal} setShowModal={setShowModal}/>
        </>
    );
};

const EmailSearch = (props) => {
    const {auctions, handleEdit, selectedAuction, showModal, setShowModal} = props;
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);



    const handleSearch = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        setQuery(searchTerm);
        if (searchTerm.length > 2) {
            setResults(auctions.filter(auction => auction.name.toLowerCase().includes(searchTerm)));
        } else {
            setResults([]);
        }
    };

    return (
        <>
            <Container>
                <Form className="my-3">
                    <Form.Group controlId="emailSearch">
                        <Form.Label>Zoek op naam</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Geef naam in"
                            value={query}
                            onChange={handleSearch}
                        />
                    </Form.Group>
                </Form>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Naam</th>
                        <th>Beschrijving</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {query.length > 2 ? results.map(auction => (
                        <tr key={auction.id}>
                            <td>{auction.name}</td>
                            <td>{auction.description}</td>
                            <td>{auction.status}</td>
                            <td>
                                <Button variant="outline-dark" className={"border-0"} size="sm" onClick={() => handleEdit(auction)}><i className={"bi bi-pencil"}></i></Button>
                            </td>
                        </tr>
                    )) : auctions.map(auction => (
                        <tr key={auction.id}>
                            <td>{auction.name}</td>
                            <td>{auction.description}</td>
                            <td>{auction.status}</td>
                            <td>
                                <Button variant="outline-dark" className={"border-0"} size="sm" onClick={() => handleEdit(auction)}> <i className={"bi bi-pencil"}></i> </Button>

                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </Container>

            {selectedAuction ? (
                <Modal show={showModal} size={"lg"} onHide={() => setShowModal(false)}>
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                ha
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h3>ha</h3>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>
                                Blacklist
                            </Button>
                            <Button variant="danger" onClick={() => setShowModal(false)}>
                                Verwijder gebruiker
                            </Button>
                            <Button variant="primary" onClick={() => setShowModal(false)}>
                                Sluit
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            ) : ""}
        </>
    );
};

const AdminManageUsers = (props) => {
    const {auctions, setAuctions, selectedAuction, setSelectedAuction, showModal, setShowModal} = props;
    const handleEdit = auction => {
        setSelectedAuction(auction);
        setShowModal(true);
    };

    const handleDelete = auction => {
    };

    return (
        <Container>
            <EmailSearch handleEdit={handleEdit} handleDelete={handleDelete} auctions={auctions} setAuctions={setAuctions} selectedAuction={selectedAuction} setSelectedAuction={setSelectedAuction} showModal={showModal} setShowModal={setShowModal} />
        </Container>
    );
};

export { AdminDisabledAuctionsPage };