import { Link, useLocation } from "react-router-dom";
import { Nav, NavDropdown } from "react-bootstrap";
import React, { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { useTranslation } from 'react-i18next';

export function UserNavigation(props) {

    const { userMetadata } = useContext(AuthContext);
    const user = userMetadata;
    const location = useLocation();
    const { t } = useTranslation();

    const isActive = (path) => {
        return location.pathname === path;
    };

    if (!user) return "Loading";

    if (user.type === "seller") {
        return (
            <>
                <Nav className={"bg-dark justify-content-center sticky-top under-nav"} variant="pills" defaultActiveKey="/dashboard/profiel">
                    <NavDropdown
                        className={"nav-dropdown"}
                        title={t('navbar_title1')}
                        menuVariant="dark"
                    >
                        <NavDropdown.Item as={Link} to={"/dashboard/mijn-veilingen"}>{t('navbar_1')}</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={"/dashboard/maak-veiling"}>{t('navbar_2')}</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={"/dashboard/plaats-veiling-opnieuw"}>{t('navbar_3')}</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown
                        className={"nav-dropdown"}
                        title={t('navbar_title2')}
                        menuVariant="dark"
                    >
                        <NavDropdown.Item as={Link} to={"/dashboard/afspraken"}>{t('navbar_4')}</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={"/dashboard/agenda"}>{t('navbar_5')}</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Item>
                        <Nav.Link className={"bg-dark text-light"} as={Link} to="/dashboard/biedingen"
                            active={isActive("/dashboard/biedingen")}>
                            {t('navbar_title3')}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className={"bg-dark text-light"} as={Link} to="/dashboard/profiel"
                            active={isActive("/dashboard/profiel")}>
                            {t('navbar_title4')}
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </>
        );
    }

    else if (user.type !== "seller") {
        return (
            <>
                <Nav className={"bg-dark justify-content-center sticky-top under-nav"} variant="pills" defaultActiveKey="/dashboard/profiel">
                    <NavDropdown
                        className={"nav-dropdown"}
                        title={t('navbar_title1')}
                        menuVariant="dark"
                    >
                        <NavDropdown.Item as={Link} to={"/dashboard/winnende-veilingen"}>{t('navbar_6')}</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={"/dashboard/volgend"}>{t('navbar_7')}</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={"/veilingen/"}>{t('navbar_8')}</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={"/dashboard/gewonnen"}>{t('navbar_9')}</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown
                        className={"nav-dropdown"}
                        title={t('navbar_title2')}
                        menuVariant="dark"
                    >
                        <NavDropdown.Item as={Link} to={"/dashboard/afspraken"}>{t('navbar_4')}</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={"/dashboard/agenda"}>{t('navbar_5')}</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Item>
                        <Nav.Link className={"bg-dark text-light"} as={Link} to="/dashboard/profiel"
                            active={isActive("/dashboard/profiel")}>
                            {t('navbar_title4')}
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </>
        )
    }
}